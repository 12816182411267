import React, { FC, FocusEvent, useCallback, useMemo } from 'react';
import useRoutes from 'hooks/useRoutes';
import useMessage from 'components/Message/useMessage';
import ActionIcon from 'components/Header/components/ActionIcon';
import { default as ActionIconModule } from 'modules/header/components/ActionIcon'; // eslint-disable-line import/no-named-default
import { userActive } from '@xxxlgroup/hydra-icons';
import LogoutFlyout from 'modules/customer-authentication/components/LogoutFlyout';
import useRenderHeaderActionIcons from 'modules/customer-authentication/hooks/useRenderHeaderActionIcons';
import { useNewHeader } from 'modules/header/Header.conf';

import styles from 'modules/customer-authentication/components/HeaderLogoutActionIcon/HeaderLogoutActionIcon.scss';

interface HeaderLogoutActionIconProps {
  /** Boolean: if mobile layout or not */
  isMobileLayout?: boolean;
  /** callback onFocus */
  onFocus?: (event: FocusEvent) => void;
  /** callback onBlur */
  onBlur?: (event: FocusEvent) => void;
  /** callback onMouseEnter */
  onMouseEnter?: () => void;
  /** callback onMouseLeave */
  onMouseLeave?: () => void;
  /** Boolean: Force Flyout to close after page change */
  noFlyout?: boolean;
}

const HeaderLogoutActionIcon: FC<HeaderLogoutActionIconProps> = ({
  noFlyout,
  isMobileLayout,
  onBlur,
  onFocus,
  onMouseEnter,
  onMouseLeave,
}) => {
  const { customerAccount } = useRoutes();
  const view = useRenderHeaderActionIcons(false);
  const [ariaLabel, label] = useMessage([
    'logoutAriaLabel.link.ariaLabel',
    'account.myaccount.title',
  ]);

  const handleFocus = useCallback(
    (event: FocusEvent) => {
      if (!isMobileLayout && onFocus) {
        onFocus(event);
      }
    },
    [isMobileLayout, onFocus],
  );

  const handleBlur = useCallback(
    (event: FocusEvent) => {
      if (!isMobileLayout && onBlur) {
        onBlur(event);
      }
    },
    [isMobileLayout, onBlur],
  );

  const UsedActionIcon = useNewHeader() ? ActionIconModule : ActionIcon;
  const className = useMemo(() => ({ content: styles.content }), []);

  return view ? (
    <UsedActionIcon
      ariaLabel={ariaLabel}
      className={className}
      dataPurpose="header.logout.actionIcon"
      glyph={userActive}
      isMobileLayout={isMobileLayout}
      label={label}
      link={`/${customerAccount.name}`}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {!noFlyout && <LogoutFlyout />}
    </UsedActionIcon>
  ) : null;
};

export default React.memo(HeaderLogoutActionIcon);
