import { Entry } from 'types/types';

/**
 * It calculates the amount of the product items in the array of entries
 */
export const getTotalProductsAmount = (entries: Entry[]): number => {
  const initialValue = 0;

  return (
    entries?.reduce((accumulator, { unitBasedCalculation, quantity }) => {
      const unitQuantity = unitBasedCalculation?.packageAmount;
      return accumulator + (Number(unitQuantity) || Number(quantity));
    }, initialValue) || initialValue
  );
};
