import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Heading, Loading } from '@xxxlgroup/hydra-ui-components';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';
import { renderError } from 'modules/favourites/utils/errors';
import { getCurrentCart, uniqueProducts } from 'utils/cart';
import { handleNotUnauthorized } from 'utils/customHandlers';
import { Types } from 'types';
import ErrorBoundary from 'components/ErrorBoundary';
import useRoutes from 'hooks/useRoutes';
import useMessage from 'components/Message/useMessage';
import useReservationsRemove from 'components/Entries/utils/actions/useReservationsRemove';
import { getTotalProductsAmount } from 'utils/cart/misc';
import Entry from 'components/Entries/EntryItems/CartMini';
import ListingMini from 'components/Entries/EntryLists/ListingMini';
import styles from 'components/Entries/EntryLists/ReservationsMini/ReservationsMini.scss';

const ReservationsMini = ({ reservationCarts = null }) => {
  const cart = useMemo(() => getCurrentCart(reservationCarts), [reservationCarts]);
  const { error, loading } = reservationCarts || {};
  const { entries = [], code: cartCode, subTotal } = cart;
  const { reservationPage } = useRoutes();
  const { remove, errors: errorsOnRemove } = useReservationsRemove(cartCode, entries);
  const showEntries = !isArrayEmpty(entries);
  const showErrorsOnRemove = !isArrayEmpty(errorsOnRemove);
  const numberOfEntries = getTotalProductsAmount(entries);
  const uniqueEntries = uniqueProducts(cart);
  const allEntries = entries.length > uniqueEntries.length ? entries : null;
  const errorHandler = handleNotUnauthorized(error);

  const [heading, entriesCount] = useMessage(
    ['wxs.reservation.title', 'wxs.reservation.number.articles'],
    {
      entries: numberOfEntries,
    },
  );

  if (loading) {
    return <Loading />;
  }

  if (errorHandler) {
    return errorHandler;
  }

  if (!cartCode) {
    return null;
  }

  return (
    <ErrorBoundary withStatusCode>
      <div className={styles.wrapper} data-testid="miniCart.wrapper">
        <Heading
          className={styles.title}
          content={heading}
          data-purpose="mini.reservations.heading"
          level={4}
        />
        {showEntries && <div className={styles.count}>{entriesCount}</div>}
        {showErrorsOnRemove && renderError(errorsOnRemove)}
        {showEntries && (
          <ListingMini
            linkToPage={`/${reservationPage?.name}`}
            priceSummary={subTotal?.value}
            toPageMessageCode="wxs.reservation.to.reservation"
            trackingData={{ purpose: 'reservations.mini.to.reservation.button' }}
          >
            {uniqueEntries.map((entry) => (
              <Entry
                allEntries={allEntries}
                cartCode={cartCode}
                entryData={entry}
                key={entry.entryNumber}
                onRemove={remove}
              />
            ))}
          </ListingMini>
        )}
      </div>
    </ErrorBoundary>
  );
};

ReservationsMini.propTypes = {
  /* [GraphQL] the (reservation-)carts object of the current user */
  reservationCarts: Types.graphqlResult({
    getCarts: PropTypes.shape({
      carts: PropTypes.arrayOf(Types.cart),
    }),
  }).isRequired,
};

export default ReservationsMini;
