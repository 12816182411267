import React, { FC } from 'react';
import classnames from 'classnames';
import useMessage from 'components/Message/useMessage';
import styles from 'components/ProductNumber/ProductNumber.scss';

interface ProductNumberProps {
  /** @ignore */
  className?: string;
  /** Sets the product/article number for the component */
  content: string;
}

const ProductNumber: FC<ProductNumberProps> = ({ className, content }) => {
  const articleNumber = useMessage('wxs.product.articleNumber');
  const number = content.replace(/^0{2}/, '');

  return (
    <div className={classnames(styles.number, className)} data-purpose="product.productNumber">
      {articleNumber} {number}
    </div>
  );
};

export default ProductNumber;
