import { useCallback } from 'react';

import { Cart } from 'graphql-types/generated/types-generated';
import { ApolloQueryResult, OperationVariables, useMutation } from '@apollo/client';
import { UPDATE_CARTS } from 'pages/Cart/Cart.mutation';
import { manageIdStorage, IdKeys } from 'utils/manageIdStorage';

interface UseMergeCartProps {
  cartId?: string;
  isAnonymous: boolean;
  refetchCart: (
    variables?: Partial<OperationVariables>,
  ) => Promise<ApolloQueryResult<{ getCarts?: { carts: Cart[] } }>>;
  refetchReservation: (
    variables?: Partial<OperationVariables>,
  ) => Promise<ApolloQueryResult<{ getCarts?: { carts: Cart[] } }>>;
  reservationId?: string;
}

enum OrderType {
  ORDER = 'ORDER',
  RESERVATION = 'RESERVATION',
}

const handleCartMerge = async (
  anonId: string,
  updateCartsMutate: any,
  refetchCart: any,
  type: OrderType.ORDER | OrderType.RESERVATION,
  cartId?: string,
) => {
  const dataCart = await updateCartsMutate({
    variables: {
      cartId: cartId ? cartId.toString() : 'current',
      cartIdAnon: anonId,
      type,
    },
  });

  if (dataCart) {
    await refetchCart();
  }

  manageIdStorage.remove(
    type === OrderType.ORDER ? IdKeys.CART_ID_ANON : IdKeys.RESERVATION_ID_ANON,
  );
};

const useMergeCart = ({
  cartId,
  refetchCart,
  isAnonymous,
  refetchReservation,
  reservationId,
}: UseMergeCartProps) => {
  const [updateCartsMutate] = useMutation(UPDATE_CARTS);

  return useCallback(async () => {
    if (!isAnonymous) {
      const cartIdAnon = manageIdStorage.get(IdKeys.CART_ID_ANON);
      const reservationIdAnon = manageIdStorage.get(IdKeys.RESERVATION_ID_ANON);

      const isOrderCart = cartIdAnon && cartIdAnon !== cartId && !reservationIdAnon;
      const isReservationCart =
        reservationIdAnon && reservationIdAnon !== reservationId && !cartIdAnon;
      const isOrderAndReservationCart =
        cartIdAnon &&
        reservationIdAnon &&
        cartIdAnon !== cartId &&
        reservationIdAnon !== reservationId;

      if (isOrderCart || isOrderAndReservationCart) {
        await handleCartMerge(cartIdAnon, updateCartsMutate, refetchCart, OrderType.ORDER, cartId);
      }
      if (isReservationCart || isOrderAndReservationCart) {
        await handleCartMerge(
          reservationIdAnon,
          updateCartsMutate,
          refetchReservation,
          OrderType.RESERVATION,
          reservationId,
        );
      }
    }
  }, [cartId, isAnonymous, refetchCart, refetchReservation, reservationId, updateCartsMutate]);
};

export default useMergeCart;
