import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';
import { Modal } from '@xxxlgroup/hydra-ui-components';
import { noop } from '@xxxlgroup/hydra-utils/common';
import useMessage from 'components/Message/useMessage';
import LoginForm from 'modules/customer-authentication/components/LoginForm';

const LoginModal = ({ onClose, onLogin }) => {
  const [heading, setHeading] = useState(null);
  const [close] = useMessage(['wxs.modal.closeButton.ariaLabel']);
  const i18nModal = useMemo(() => ({ close }), [close]);

  return (
    <Modal heading={heading} variant="sidebar" i18n={i18nModal} onClose={onClose}>
      <LoginForm
        onViewChanged={({ heading: changedHeading }) => {
          setHeading(changedHeading);
        }}
        onLogin={onLogin}
        onClickSignUp={onClose}
      />
    </Modal>
  );
};

LoginModal.propTypes = {
  /* Callback to be called when the modal is closed, passed down to Modal component. */
  onClose: PropTypes.func.isRequired,
  /* Callback to be called when login process ends. */
  onLogin: PropTypes.func,
};

LoginModal.defaultProps = {
  onLogin: noop,
};

export default track(tagComponent('LoginModal'))(LoginModal);
