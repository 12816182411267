import { local } from '@xxxlgroup/hydra-utils/storage';

export enum IdKeys {
  CART_ID_ANON = 'cartIdAnon',
  RESERVATION_ID_ANON = 'reservationIdAnon',
}

interface ManageIdStorage {
  get: (key: IdKeys) => string | null;
  set: (key: IdKeys, id?: string | null) => void;
  remove: (key: IdKeys) => void;
}

export const manageIdStorage: ManageIdStorage = {
  get: (key) => local.getItem(key),

  set: (key, id) => {
    const idFromStorage = manageIdStorage.get(key);

    if (id && idFromStorage !== id) {
      local.setItem(key, id);
    }
  },

  remove: (key) => local.removeItem(key),
};
