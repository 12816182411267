import React, { useState } from 'react';
import classNames from 'classnames';
import { Heading, Input } from '@xxxlgroup/hydra-ui-components';
import { SEOLevel } from '@xxxlgroup/hydra-ui-components/dist/common/types/typeDefinitions';
import useMessage from 'components/Message/useMessage';
import useSavePreferences from 'modules/customer-account/hooks/useSavePreferences';
import { PreferenceActions } from 'modules/customer-account/pages/PreferencesData/preferences.types';

import styles from 'modules/customer-account/components/AccountGreeting/components/AccountGreetingWithInput/AccountGreetingWithInput.scss';

interface AccountGreetingWithInputProps {
  /** @ignore */
  className?: string;
  /** SEOLevel applied to the heading */
  SEOLevel?: boolean | SEOLevel;
}

const AccountGreetingWithInput = ({ className, SEOLevel }: AccountGreetingWithInputProps) => {
  const [profileName, setProfileName] = useState('');
  const { savePreferences } = useSavePreferences();

  const [title, inputPlaceholder, cancel] = useMessage([
    'account.greeting.input.title',
    'account.greeting.input.placeholder',
    'wxs.input.cancel',
  ]);

  const handleChange = (_event: Event, value: string) => {
    setProfileName(value);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    savePreferences(PreferenceActions.SET_GREETING, {
      greeting: profileName,
    });
  };

  return (
    <div className={styles.wrapper}>
      <Heading
        className={classNames(styles.heading, className)}
        content={title}
        level={2}
        noMargin
        SEO={SEOLevel}
      />
      <form onSubmit={handleSubmit} className={styles.greetingForm}>
        <Input
          className={classNames(styles.greetingInput, {
            [styles.greetingInputStep1]: profileName?.length > 7,
            [styles.greetingInputStep2]: profileName?.length > 11,
          })}
          hideLabel
          i18n={{ cancel }}
          label={inputPlaceholder}
          onChange={handleChange}
          placeholder={inputPlaceholder}
          type="text"
        />
      </form>
    </div>
  );
};

export default AccountGreetingWithInput;
