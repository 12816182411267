/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, MouseEvent, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import track from 'react-tracking';
import {
  Button,
  FeedbackCard,
  Heading,
  IconButton,
  Link,
  RadioButton,
} from '@xxxlgroup/hydra-ui-components';
import * as icons from '@xxxlgroup/hydra-icons';
import { Field, Form } from 'components/Form';
import Message from 'components/Message';
import useMessage from 'components/Message/useMessage';
import {
  COMPONENT_NAME,
  LanguageSelectorFormFields,
  LanguageSelectorProps,
  useLanguageSelector,
} from 'components/MetaNavigation/components/LanguageSelector/useLanguageSelector';
import { COOKIE_NAME } from 'utils/i18n';
import { tagComponent } from 'utils/tracking/tracking';

import styles from './LanguageSelector.scss';

const LanguageSelector: FC = (props: LanguageSelectorProps) => {
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null);
  const { currentLanguage, handleChange, handleClick, handleSubmit, isOpen, languages } =
    useLanguageSelector(props);
  const [closeAriaLabel, heading, submit, betaNoteTitle, betaNoteDescription] = useMessage(
    [
      'wxs.modal.closeButton.ariaLabel',
      'language.select',
      'account.userDataEdit.submit',
      'language.beta.note.title',
      'language.beta.note',
    ],
    { language: selectedLanguage },
  );

  const locale = useMemo(() => currentLanguage?.split('_')[0].toUpperCase(), [currentLanguage]);

  useEffect(() => {
    setSelectedLanguage(currentLanguage);
  }, [isOpen, currentLanguage]);

  const handleLanguageChange = (event: MouseEvent<HTMLInputElement>) => {
    setSelectedLanguage((event.target as HTMLInputElement).value);
  };

  const renderRadioButtons = useMemo(
    () =>
      languages.map(({ isocode }) => {
        const isoCode = isocode as string;
        return (
          <RadioButton
            value={isoCode}
            key={isoCode}
            data-testid="languageselector.radio"
            onClick={handleLanguageChange}
          >
            <div className={styles.radio}>
              <span>
                <Message code={`language.${isoCode.split('_')[0]}`} />
              </span>
              <Message code="language.beta" values={{ language: isoCode }}>
                {(messages) =>
                  messages ? (
                    <span data-testid="beta.mark" className={styles.betaMark}>
                      {messages}
                    </span>
                  ) : (
                    <></>
                  )
                }
              </Message>
            </div>
          </RadioButton>
        );
      }),
    [languages],
  );

  const renderFlyOut = useMemo(
    () => (
      <section className={styles.dialog} role="dialog" data-testid="languageselector.dialog">
        <IconButton
          className={styles.close}
          ariaLabel={closeAriaLabel}
          data-testid="languageselector.close"
          glyph={icons.x}
          onClick={handleClick}
        />
        <Heading SEO="h2" level={4} content={heading} />
        <Form<LanguageSelectorFormFields>
          id="language-selector"
          initialValues={{ [COOKIE_NAME]: currentLanguage as string }}
          onSubmit={handleSubmit}
          onValueChange={handleChange}
          className={styles.form}
        >
          <Field name={COOKIE_NAME} size="full" type="radiogroup">
            {renderRadioButtons}
          </Field>
          {betaNoteDescription && (
            <FeedbackCard
              timerDuration="relaxed"
              fadeIn
              className={styles.betaNoteWrapper}
              data-testid="beta.note"
              title={betaNoteTitle}
            >
              {betaNoteDescription}
            </FeedbackCard>
          )}
          <Button type="submit" layout="block" data-testid="languageselector.submit">
            {submit}
          </Button>
        </Form>
      </section>
    ),
    [
      betaNoteDescription,
      betaNoteTitle,
      closeAriaLabel,
      currentLanguage,
      handleChange,
      handleClick,
      handleSubmit,
      heading,
      renderRadioButtons,
      submit,
    ],
  );

  const className = classNames(styles.wrapper, { [styles.open]: isOpen });

  return currentLanguage && languages.length > 1 ? (
    <>
      <div className={className}>
        <Link
          className={styles.activator}
          data-testid="languageselector.activator"
          link="button"
          glyphAfter={icons.chevronDown}
          onClick={handleClick}
          theme="ash"
        >
          {locale}
        </Link>
      </div>
      {isOpen ? renderFlyOut : null}
    </>
  ) : null;
};

export default track(tagComponent(COMPONENT_NAME))(LanguageSelector);
