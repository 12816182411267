import { MouseEventHandler, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';
import CountryContext from 'Providers/IntlProvider/CountryContext';
import useMandantConfig from 'hooks/useMandantConfig';
import { Language } from 'graphql-types/generated/types-generated';
import { MAX_AGE_NINETY_DAYS, getCookie, setCookie, removeCookie } from 'utils/cookie';
import { COOKIE_NAME } from 'utils/i18n';
import { useTracking } from 'utils/tracking/hooks';

export interface LanguageSelectorFormFields {
  [COOKIE_NAME]: string;
}

export const COMPONENT_NAME = 'LanguageSelector';

export interface LanguageSelectorProps {}

export const useLanguageSelector = (props?: LanguageSelectorProps) => {
  const { languages: mandantConfigLanguages } = useMandantConfig();
  const tracking = useTracking(props || {}, COMPONENT_NAME);
  const [currentLanguage, setCurrentLanguage] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { language: countryContextLanguage } = useContext(CountryContext) as unknown as {
    language: { locale: string; region: string; languageCode: string };
  };

  const defaultLanguage = `${countryContextLanguage.locale}_${countryContextLanguage.languageCode?.toUpperCase()}`;

  const languages: Language[] = useMemo(
    () => mandantConfigLanguages?.languages ?? [],
    [mandantConfigLanguages?.languages],
  );

  useEffect(() => {
    const cookieLanguage = getCookie(COOKIE_NAME);
    if (!isArrayEmpty(languages)) {
      let detectedLanguage =
        cookieLanguage && languages.find(({ isocode }) => cookieLanguage === isocode);

      detectedLanguage =
        detectedLanguage ||
        languages.find(({ isocode }) => isocode?.startsWith(`${countryContextLanguage.locale}_`));

      detectedLanguage && setCurrentLanguage(detectedLanguage.isocode as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = useCallback(
    (values: LanguageSelectorFormFields) => {
      tracking('onChange', {
        event: { type: 'click', purpose: 'language.selector' },
        props: { language: values[COOKIE_NAME] },
      });
    },
    [tracking],
  );

  const handleClick: MouseEventHandler = useCallback(
    (event) => {
      tracking(event);
      setIsOpen((value) => !value);
    },
    [tracking],
  );

  const setLanguage = useCallback(
    (isoCode: string) => {
      setCurrentLanguage(isoCode);
      isoCode?.startsWith(`${countryContextLanguage.locale}_`)
        ? removeCookie(COOKIE_NAME)
        : setCookie(COOKIE_NAME, isoCode, { maxAge: MAX_AGE_NINETY_DAYS });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.location.reload(true); // Needed to bypass the cache on Firefox. Non-standard though, that's why TypeScript is throwing an error.
    },
    [countryContextLanguage.locale],
  );

  const handleSubmit = useCallback(
    (values: LanguageSelectorFormFields) => {
      tracking('onSubmit', values);
      setIsOpen(false);
      setLanguage(values[COOKIE_NAME]);
    },
    [setLanguage, tracking],
  );

  const getActiveLocale = useCallback(
    () =>
      (languages.length > 1 && currentLanguage
        ? currentLanguage
        : countryContextLanguage.region
      ).replace('_', '-'),
    [currentLanguage, languages, countryContextLanguage],
  );

  return {
    currentLanguage,
    handleChange,
    handleClick,
    handleSubmit,
    isOpen,
    languages,
    getActiveLocale,
    defaultLanguage,
  };
};
