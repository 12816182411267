import React, { memo, useContext, useCallback } from 'react';

import { Loading, Heading, FeedbackCard } from '@xxxlgroup/hydra-ui-components';
import useRoutes from 'hooks/useRoutes';
import Entry from 'modules/favourites/components/FavouritesMini';
import ListingMini from 'components/Entries/EntryLists/ListingMini';
import useMessage from 'components/Message/useMessage';
import FavouritesFlyoutEmpty from 'modules/favourites/components/FavouritesFlyoutEmpty';
import { FavouritesContext } from 'modules/favourites/pages/Favourites/Favourites.state';
import { getFavouritesProducts, getProductCode } from 'modules/favourites/utils/favourites';
import { LOCALIZATION } from 'modules/favourites/constants';

import useRemoveFavourites from 'modules/favourites/hooks/useRemoveFavourites';
import type { FavouritesContextInterface } from 'modules/favourites/pages/Favourites/Favourites.types';

import styles from 'modules/favourites/components/FavouritesFlyout/FavouritesFlyout.scss';

const FavouritesFlyout = memo(() => {
  const { favouritesPage } = useRoutes();
  const { favouritesState } = useContext<FavouritesContextInterface>(FavouritesContext);
  const { favourites } = favouritesState;
  const { loading, errors: errorsGetData } = favourites;

  const products = getFavouritesProducts(favourites);
  const hasProducts = products.length > 0;
  const { removeFavourite } = useRemoveFavourites();

  const [heading, entriesCount, getDataError] = useMessage(
    [
      LOCALIZATION.favouritesHeadingPage,
      LOCALIZATION.favouritesNumberArticles,
      LOCALIZATION.favouritesGetDataError,
    ],
    { entries: products.length },
  );

  const handleRemoveEntry = useCallback(
    async (entryId?: string | number | null) => {
      const code = getProductCode(products, entryId?.toString() ?? '');
      if (code) {
        removeFavourite(code.toString());
      }
    },
    [products, removeFavourite],
  );

  return (
    <div className={styles.wrapper}>
      <Heading level={4} content={heading} SEO="h3" data-purpose="favourites.heading" />
      {errorsGetData && <FeedbackCard variant="error">{getDataError}</FeedbackCard>}
      {hasProducts && <div className={styles.count}>{entriesCount}</div>}
      {loading && <Loading />}
      {hasProducts ? (
        <ListingMini
          linkToPage={favouritesPage.root}
          toPageMessageCode="favourites.to.favourites"
          trackingData={{ purpose: 'favourites.mini.to.favourites.button' }}
        >
          {products.map((entry) => (
            <Entry entryData={entry} key={entry.id} onRemove={handleRemoveEntry} />
          ))}
        </ListingMini>
      ) : (
        <FavouritesFlyoutEmpty />
      )}
    </div>
  );
});

export default FavouritesFlyout;
