import { useCallback } from 'react';
import { debouncing } from 'utils/function';
import { SEARCH_TYPE, useCategoryContext } from 'pages/CategoryView/provider/Category.provider';
import type { useTracking } from 'utils/tracking/hooks';

const debounceCall = debouncing((func: () => void) => {
  func();
}, 200);

const useSearchResultsTracking = (tracking: ReturnType<typeof useTracking>) => {
  const { searchTracking } = useCategoryContext();

  return {
    searchResultTracking: useCallback(() => {
      debounceCall(() => {
        if (!searchTracking.current.method) return;

        const resultProps = {
          search_method: searchTracking.current.method,
          search_products: searchTracking.current.productCodes,
          search_redirect: searchTracking.current.redirect,
          search_results_number: searchTracking.current.totalResults,
          search_term: searchTracking.current.term,
          search_type: SEARCH_TYPE[searchTracking.current.method],
          searchpass: searchTracking.current.searchPass,
        };

        searchTracking.current.method !== 'location_change' &&
          tracking('SearchResult', {
            type: 'Search',
            event: {
              type: 'Search',
              purpose: 'search.result',
            },
            props: resultProps,
          });
        window.dispatchEvent(
          new CustomEvent('search-collector', {
            detail: resultProps,
          }),
        );

        searchTracking.current.method = '';
        searchTracking.current.productCodes = [];
        searchTracking.current.redirect = false;
        searchTracking.current.searchPass = '';
        searchTracking.current.term = '';
        searchTracking.current.totalResults = 0;
      }, 1000);
    }, [searchTracking, tracking]),
    searchTracking,
  };
};

export default useSearchResultsTracking;
