import React from 'react';
import PropTypes from 'prop-types';
import styles from 'components/ScrollBox/ScrollBox.scss';

const ScrollBox = ({ children, dataPurpose }) => (
  <div className={styles.scrollBoxWrapper} data-testid="scrollbox" data-purpose={dataPurpose}>
    <div className={styles.scrollBoxContent}>{children}</div>
  </div>
);

ScrollBox.propTypes = {
  children: PropTypes.node.isRequired,
  dataPurpose: PropTypes.string,
};

ScrollBox.defaultProps = {
  dataPurpose: null,
};

export default ScrollBox;
