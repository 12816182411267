import PropTypes from 'prop-types';
import { ProductTypes, Types } from 'types';

const getProductEntryProps = (additionalProps = {}) =>
  ProductTypes.configurableProductTypes(
    ['name', 'url', 'urlFromSeoData', 'isConfiguredProduct', 'priceData', 'mediaData', 'code'],
    {
      /** Product attributes */
      attributes: ProductTypes.attributes,
      /** Product color */
      color: PropTypes.string,
      /** Energy efficiency */
      energyEfficiency: Types.energyEfficiency,
      /** Product image data */
      picture: PropTypes.shape({ altText: PropTypes.string, cdnFilename: PropTypes.string }),
      /** Object with different variant-options */
      variantContainerData: PropTypes.shape({
        variantGroups: PropTypes.oneOfType([PropTypes.object]),
      }),
      ...additionalProps,
    },
  );

const ENTRY_TYPES_SHARED = (additionalProductProps) => ({
  /** The entry number */
  entryNumber: PropTypes.number,
  /** Is the product a free gift voucher */
  freeGiftProductVoucher: PropTypes.bool,
  /** Product specific information for this entry */
  product: getProductEntryProps(additionalProductProps),
  /** the number of products - not for unit based products */
  quantity: PropTypes.number,
});

export const ENTRY_TYPES_FREE_GIFT = PropTypes.shape({ ...ENTRY_TYPES_SHARED() });

export const ENTRY_TYPES_CART = (additionalProductProps) =>
  PropTypes.shape({
    item: PropTypes.shape({
      ...ENTRY_TYPES_SHARED(additionalProductProps),
      /** The entry code */
      code: PropTypes.string,
    }),
  });

export const ENTRY_TYPES_CHECKOUT = (additionalProductProps) =>
  PropTypes.shape({
    ...ENTRY_TYPES_SHARED(additionalProductProps),
    /** Total price for product (single product price * quantity ) */
    totalPrice: PropTypes.shape({
      value: PropTypes.number,
    }),
    /** Specific information for the package based unit products (like floors) */
    unitBasedCalculation: PropTypes.shape({
      deliveryAmount: PropTypes.number,
      packageAmount: PropTypes.number,
    }),
    /** Specific information for the point of service, if the delivery option is self service */
    pointOfService: PropTypes.shape({
      name: PropTypes.string,
    }),
  });

export const ENTRY_TYPES_RESERVATION_SUMMARY = (additionalProductProps) =>
  PropTypes.shape({
    ...ENTRY_TYPES_SHARED(additionalProductProps),
    /** Total price for product (single product price * quantity ) */
    totalPrice: PropTypes.shape({
      value: PropTypes.number,
    }),
    /** Specific information for the package based unit products (like floors) */
    unitBasedCalculation: PropTypes.shape({
      deliveryAmount: PropTypes.number,
      packageAmount: PropTypes.number,
    }),
  });

export const ENTRY_TYPES_RESERVATION_PAGE = PropTypes.shape({
  ...ENTRY_TYPES_SHARED({
    /** A flag if the product is still visible in the shop */
    visible: PropTypes.bool,
    /** The product reservation data */
    reservationData: PropTypes.shape({
      reservable: PropTypes.bool,
    }),
  }),
  /** Total price for product (single product price * quantity ) */
  totalPrice: PropTypes.shape({
    value: PropTypes.number,
  }),
  /** The selected quantity from the user - can be different than the *quantity* that Hybris returns in case of reached stock limit */
  selectedQuantity: PropTypes.number,
});
