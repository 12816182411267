import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@xxxlgroup/hydra-ui-components';
import { useGreeting } from 'modules/customer-account/hooks/useGreeting';

const Greeting = ({ className, children, skeletonClassName }) => {
  const { greeting, firstName, lastName, profileName, email, loading, hasError } = useGreeting();

  const isChildrenFn = typeof children === 'function';
  const content = isChildrenFn
    ? children({ greeting, firstName, lastName, profileName, email })
    : greeting;

  if (hasError) {
    return null;
  }

  return (
    <div className={className}>
      {loading ? <Skeleton className={skeletonClassName} /> : content}
    </div>
  );
};

Greeting.propTypes = {
  /** @ignore */
  className: PropTypes.string,
  /** @ignore */
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  /** The className for the skeleton component. */
  skeletonClassName: PropTypes.string,
};

Greeting.defaultProps = {
  className: null,
  children: null,
  skeletonClassName: null,
};

export default Greeting;
