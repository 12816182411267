import React from 'react';
import PropTypes from 'prop-types';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import EntryCompound from 'components/Entries/EntryCompound';
import Attributes from 'components/Entries/EntryCompound/components/Attributes';
import EnergyLabel from 'components/EnergyLabel';
import DeleteButton from 'components/Entries/EntryCompound/components/DeleteButton';
import Image from 'components/Entries/EntryCompound/components/Image';
import Name from 'components/Entries/EntryCompound/components/Name';
import OverlayDeleted from 'components/Entries/EntryCompound/components/OverlayDeleted';
import Quantity from 'components/Entries/EntryCompound/components/Quantity';
import Price from 'components/Entries/EntryCompound/components/Price';
import EcoTax from 'components/Entries/EntryCompound/components/EcoTax';
import { ENTRY_TYPES_CART } from 'components/Entries/Entries.types';
import useOverlayDeletedTimer from 'components/Entries/EntryCompound/components/OverlayDeleted/useOverlayDeletedTimer';
import styles from 'components/Entries/EntryItems/CartMini/CartMini.scss';

const CartMini = (props) => {
  const tracking = useTracking(props, `CartMini`);
  const {
    cartCode,
    entryData,
    entryData: {
      additionalExpenses,
      freeGiftProductVoucher,
      quantity,
      oldTotalPrice,
      product,
      totalPrice,
      unitBasedCalculation,
      updateable,
    },
    allEntries,
    onRemove,
  } = props;

  const { energyEfficiencyData, mediaData } = product;
  const hasMultipleEntries = quantity > 1;
  const duplicates =
    (allEntries &&
      hasMultipleEntries &&
      allEntries.filter((item) => item.product.code === product.code)) ||
    null;

  const { initRemoving, revertRemoving, isRemoving, cancelRemoving } = useOverlayDeletedTimer({
    cartCode,
    entryData,
    onRemove,
    duplicates,
  });

  const handleRevert = (event) => {
    tracking(event, { entryData });
    revertRemoving();
  };

  const handleRemove = () => {
    tracking(`deleteCart`, {
      data: {
        entryData,
      },
    });
    initRemoving();
  };

  const onMouseLeave = () => {
    initRemoving();
  };

  const onMouseEnter = () => {
    cancelRemoving();
  };

  if (isRemoving) {
    return (
      <div className={styles.overlayWrapper}>
        <OverlayDeleted
          name={product.name}
          onRevert={handleRevert}
          data-purpose="productList.item.revert"
          onMouseLeave={onMouseLeave}
          onMouseEnter={onMouseEnter}
        />
      </div>
    );
  }

  return (
    <EntryCompound
      className={styles.wrapper}
      product={product}
      dataPurpose="productList.item"
      hasFreeGiftProductVoucher={freeGiftProductVoucher}
    >
      <Name headingLevel={4} className={styles.name} />
      <Image className={styles.image} />
      <Attributes withDisabledKeys className={styles.attributes} />
      {energyEfficiencyData && (
        <EnergyLabel
          className={styles.energyLabel}
          classNameLink={styles.energyLink}
          energyEfficiencyData={energyEfficiencyData}
          mediaData={mediaData}
          withDataSheet={false}
        />
      )}
      {updateable && <DeleteButton onClick={handleRemove} className={styles.button} />}
      <Quantity className={styles.quantity} amount={quantity} unitBased={unitBasedCalculation} />

      <Price
        alignment="right"
        className={styles.price}
        priceCurrentTotal={totalPrice?.value}
        priceOldTotal={oldTotalPrice?.value}
      />
      <EcoTax withTotalPrice additionalExpenses={additionalExpenses} />
    </EntryCompound>
  );
};

CartMini.propTypes = {
  /** list of all entries if there are duplicates */
  allEntries: PropTypes.arrayOf(ENTRY_TYPES_CART()),
  /** reservations cart code */
  cartCode: PropTypes.string.isRequired,
  /** the entry data */
  entryData: ENTRY_TYPES_CART().isRequired,
  /** a callback for handling the error on entry removal */
  onRemove: PropTypes.func.isRequired,
};

CartMini.defaultProps = {
  allEntries: null,
};

export default track(tagComponent('CartMini'))(CartMini);
