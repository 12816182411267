import React, { FC, FocusEvent, useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { generatePath, useHistory } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import useRoutes from 'hooks/useRoutes';
import ActionIcon from 'components/Header/components/ActionIcon';
import { default as ActionIconModule } from 'modules/header/components/ActionIcon'; // eslint-disable-line import/no-named-default
import { user } from '@xxxlgroup/hydra-icons';
import LoginModal from 'modules/customer-authentication/components/LoginModal/LoginModal';
import useMediaQuery from 'components/MediaQuery/useMediaQuery';
import { Breakpoint } from 'components/MediaQuery/MediaQuery.types';
import useRenderHeaderActionIcons from 'modules/customer-authentication/hooks/useRenderHeaderActionIcons';
import useMessage from 'components/Message/useMessage';
import LoginFlyout from 'modules/customer-authentication/components/LoginFlyout';
import { useNewHeader } from 'modules/header/Header.conf';

import styles from 'modules/customer-authentication/components/HeaderLoginActionIcon/HeaderLoginActionIcon.scss';

interface HeaderLoginActionIconProps {
  /** Boolean: if mobile layout or not */
  isMobileLayout?: boolean;
  /** callback onFocus */
  onFocus?: (event: FocusEvent) => void;
  /** callback onBlur */
  onBlur?: (event: FocusEvent) => void;
  /** callback onMouseEnter */
  onMouseEnter?: () => void;
  /** callback onMouseLeave */
  onMouseLeave?: () => void;
  /** Boolean: Force Flyout to close after page change */
  noFlyout?: boolean;
}

const HeaderLoginActionIcon: FC<HeaderLoginActionIconProps> = ({
  noFlyout,
  isMobileLayout,
  onBlur,
  onFocus,
  onMouseEnter,
  onMouseLeave,
}) => {
  const { customerAuthentication, customerAccount } = useRoutes();
  const signInRootPath = customerAuthentication.signIn.root;
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [loginFlyoutKey, setLoginFlyoutKey] = useState(uuidv4);
  const history = useHistory();
  const [loginAriaLabel, loginLabel] = useMessage([
    'login.link.ariaLabel',
    'header.actionIcons.login.label',
  ]);
  const onSignInPage = useRouteMatch(signInRootPath);
  const useModal = useMediaQuery({ smallerThan: Breakpoint.md }) && !onSignInPage;
  const view = useRenderHeaderActionIcons(true);
  const iconLink = useModal ? null : generatePath(signInRootPath);

  // cant be covered by tests as onFlyout depends on css transition
  /* istanbul ignore next */
  const onFlyout = useCallback((isVisible: boolean) => {
    // re-mount LoginFlyout on flyout hiding
    if (!isVisible) {
      setLoginFlyoutKey(uuidv4());
    }
  }, []);

  const onClickIcon = () => {
    if (useModal) {
      setLoginModalOpen(true);
    }
  };
  const onCloseModal = () => {
    setLoginModalOpen(false);
  };
  const onLogin = (data: any, errors: any) => {
    if (!errors) {
      history.push(`/${customerAccount.name}`);
    }
  };

  const handleFocus = useCallback(
    (event: FocusEvent) => {
      if (!isMobileLayout) {
        onFocus?.(event);
      }
    },
    [isMobileLayout, onFocus],
  );

  const handleBlur = useCallback(
    (event: FocusEvent) => {
      if (!isMobileLayout) {
        onBlur?.(event);
      }
    },
    [isMobileLayout, onBlur],
  );

  const UsedActionIcon = useNewHeader() ? ActionIconModule : ActionIcon;

  return view ? (
    <>
      <UsedActionIcon
        className={styles.actionIcon}
        glyph={user}
        link={iconLink}
        label={loginLabel}
        onClick={onClickIcon}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onFlyout={onFlyout}
        isMobileLayout={isMobileLayout}
        dataPurpose="header.login.actionIcon"
        ariaLabel={loginAriaLabel}
      >
        {!noFlyout && <LoginFlyout key={loginFlyoutKey} />}
      </UsedActionIcon>
      {loginModalOpen && <LoginModal onClose={onCloseModal} onLogin={onLogin} />}
    </>
  ) : null;
};

export default React.memo(HeaderLoginActionIcon);
