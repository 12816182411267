import React, {
  type FC,
  type FocusEvent,
  type FocusEventHandler,
  type KeyboardEvent,
  type MouseEvent,
  type MouseEventHandler,
  type ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classnames from 'classnames';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import Link from 'components/WebshopLink';
import { noop } from '@xxxlgroup/hydra-utils/common';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon';
import { Glyph } from '@xxxlgroup/hydra-ui-components/dist/common/types/typeDefinitions';
import useMediaQuery from 'components/MediaQuery/useMediaQuery';
import { Breakpoint } from 'components/MediaQuery/MediaQuery.types';

import styles from 'modules/header/components/ActionIcon/ActionIcon.scss';

const BADGE_PLUS_LIMIT = 100;

interface ActionIconProps {
  ariaLabel?: string;
  badge?: number;
  children?: ReactElement | false | null;
  className?: { component?: string; content?: string } | string | null;
  dataPurpose: string | null;
  glyph: Glyph;
  label?: string;
  link?: string | null;
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  onBlur?: FocusEventHandler;
  onFocus?: FocusEventHandler;
  onFlyout?: (isVisible: boolean) => void;
  onMouseEnter?: (event: MouseEvent, dataPurpose: string | null) => void;
  onMouseLeave?: MouseEventHandler<HTMLButtonElement>;
  tracking?: {
    getTrackingData: () => void;
    trackEvent: () => void;
  };
}

const ActionIcon: FC<ActionIconProps> = (props) => {
  const {
    ariaLabel = null,
    badge = 0,
    children = null,
    className = null,
    dataPurpose = null,
    glyph,
    label = null,
    link = null,
    onClick = noop,
    onFocus = noop,
    onBlur = noop,
    onMouseEnter = noop,
    onMouseLeave = noop,
    onFlyout = noop,
  } = props;

  const isMobileLayout = useMediaQuery({ smallerThan: Breakpoint.lg });
  const isTouch = useMediaQuery({ touchOnly: true });

  const [iconStyle, iconClassName] = pseudoIcon(glyph);
  const tracking = useTracking(props, 'ActionIcon');
  const wrapperRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const Element: any = link ? Link : 'button';
  const isBadgePlus = badge >= BADGE_PLUS_LIMIT;
  const elementAttributes = useMemo(
    () => ({
      'data-purpose': dataPurpose,
      ...(link ? { href: link, ariaLabel } : {}),
      ...(!link ? { 'aria-label': ariaLabel, type: 'button' } : {}),
    }),
    [link, ariaLabel, dataPurpose],
  );

  const [openFlyout, setOpenFlyout] = useState(false);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (isMobileLayout) {
        onClick(event);
      }
    },
    [onClick, isMobileLayout],
  );

  const handleFocus = useCallback(
    (event: FocusEvent) => {
      if (!isMobileLayout) {
        onFocus(event);
      }
    },
    [isMobileLayout, onFocus],
  );

  const handleBlur = useCallback(
    (event: FocusEvent) => {
      if (!isMobileLayout) {
        onBlur(event);
        setOpenFlyout(false);
      }
    },
    [isMobileLayout, onBlur],
  );

  const handleMouseEnter = useCallback(
    (event: MouseEvent) => {
      if (!isMobileLayout) {
        onMouseEnter(event, dataPurpose);
      }
    },
    [isMobileLayout, onMouseEnter, dataPurpose],
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      tracking(event);

      if (event.key === 'ArrowDown') {
        event.preventDefault();
        setOpenFlyout(true);
      }
      if (event.key === 'ArrowUp' || event.key === 'Escape') {
        event.preventDefault();
        setOpenFlyout(false);
      }
    },
    [tracking],
  );

  // transitionend event with the specific propertyName can't be simulated with jest
  /* istanbul ignore next */
  const transitionEndEvent = useCallback(
    (event: TransitionEvent) => {
      const contentElm = contentRef?.current;
      const { target, propertyName } = event;
      if (target === contentElm && propertyName === 'visibility') {
        const visibility = window
          .getComputedStyle(target as HTMLDivElement)
          .getPropertyValue('visibility');
        const isVisible = visibility === 'visible';
        onFlyout(isVisible);
      }
    },
    [onFlyout],
  );

  useEffect(() => {
    const wrapperElm = wrapperRef?.current;
    wrapperElm?.addEventListener('transitionend', transitionEndEvent);

    return () => {
      wrapperElm?.removeEventListener('transitionend', transitionEndEvent);
    };
  }, [onFlyout, transitionEndEvent]);

  const componentClassName =
    className && typeof className !== 'string' ? className?.component : className;
  const contentClassName = className && typeof className !== 'string' && className?.content;

  return (
    <div className={classnames(styles.wrapper, componentClassName)} ref={wrapperRef}>
      <Element
        onMouseEnter={handleMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={handleClick}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        data-testid="actionIcon.button"
        className={classnames(styles.button, styles.iconWrapper, iconClassName)}
        style={iconStyle}
        state={{ fromHeader: true }}
        {...elementAttributes}
      >
        {badge > 0 && (
          <div className={classnames(styles.badge, { [styles.badgePlus]: isBadgePlus })}>
            {isBadgePlus ? `${BADGE_PLUS_LIMIT - 1}+` : badge}
          </div>
        )}
        {label && <span className={styles.label}>{label}</span>}
      </Element>
      {!isTouch && children && (
        <div
          className={classnames(
            styles.content,
            { [styles.focusedContent]: openFlyout },
            contentClassName,
          )}
          ref={contentRef}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default track(tagComponent('ActionIcon'))(ActionIcon);
