import React, { FC } from 'react';
import track from 'react-tracking';

import { tagComponent } from 'utils/tracking/tracking';

import Link from 'components/WebshopLink';
import EnergyEfficiency from 'components/EnergyEfficiency';
import Message from 'components/Message';

import { Link as HydraLink } from '@xxxlgroup/hydra-ui-components';
import type {
  EnergyEfficiencyData,
  ProductMediaData,
} from 'graphql-types/generated/types-generated';

import styles from 'components/EnergyLabel/EnergyLabel.scss';

interface EnergyLabelProps {
  /** Sets a class to give customized styles to the link element. */
  classNameLink?: string;
  /** Sets a class to give customized styles to the div element. */
  className?: string;
  /** Adds the energyEfficiencyLabel */
  energyEfficiencyData: EnergyEfficiencyData;
  /** Object of arrays with media information */
  mediaData?: ProductMediaData | null;
  /** Controls if we are showing a link to data sheet */
  withDataSheet?: boolean;
  /** Controls if we are showing energy efficiency label */
  withEfficiency?: boolean;
}

const EnergyLabel: FC<EnergyLabelProps> = ({
  classNameLink,
  className,
  energyEfficiencyData,
  mediaData,
  withDataSheet = true,
  withEfficiency = true,
}) => {
  const findEuDataSheetUrl = () => {
    const euDataSheet = mediaData?.datasheets?.find(
      (sheet) => sheet?.documentType === 'EU Produktdatenblatt',
    );
    return euDataSheet?.url;
  };

  return (
    <div className={className}>
      {withEfficiency && (
        <EnergyEfficiency
          className={styles.energyLabelWrapper}
          classNameLabel={styles.energyLabel}
          energyEfficiencyData={energyEfficiencyData}
          letterSize="large"
          size="regular"
          hasInfo
        />
      )}
      {withDataSheet && findEuDataSheetUrl() && (
        <Message code="product.detail.productDataSheet">
          {(message) => (
            <Link
              as={<HydraLink typography="small" theme="concrete" />}
              href={findEuDataSheetUrl()}
              title={message}
              target="_blank"
              className={classNameLink}
            >
              {message}
            </Link>
          )}
        </Message>
      )}
    </div>
  );
};

export default track(tagComponent('EnergyLabel'))(EnergyLabel);
