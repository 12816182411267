import React, { memo } from 'react';
import { Button } from '@xxxlgroup/hydra-ui-components';
import Link from 'components/WebshopLink';
import useMessage from 'components/Message/useMessage';
import useRoutes from 'hooks/useRoutes';
import styles from 'components/Entries/EntryLists/CartMiniEmpty/CartMiniEmpty.scss';

const CartMiniEmpty = () => {
  const [noProducts, continueShopping] = useMessage([
    'wxs.checkout.cart.noproducts',
    'wxs.reservation.page.intro.summary.continueShopping',
  ]);

  const { homePage } = useRoutes();

  const handleClick = (event) => {
    event.currentTarget.blur();
  };

  return (
    <>
      <div className={styles.noProducts} data-purpose="checkout.cart.noProducts">
        {noProducts}
      </div>
      <Link
        as={<Button layout="block" variant="primary" />}
        href={homePage}
        data-purpose="productList.shopping.cart"
        onClick={handleClick}
      >
        {continueShopping}
      </Link>
    </>
  );
};

export default memo(CartMiniEmpty);
