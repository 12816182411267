import React, { useCallback } from 'react';
import { Button, Content, FeedbackCard, Link as HydraLink } from '@xxxlgroup/hydra-ui-components';
import useFeatureFlagsEnabled from 'hooks/useFeatureFlagsEnabled';
import useRoutes from 'hooks/useRoutes';
import Link from 'components/WebshopLink/WebshopLink';
import useMessage from 'components/Message/useMessage';
import { useAuth } from 'modules/customer-authentication/AuthContext';
import { LOCALIZATION } from 'modules/favourites/constants';
import track from 'react-tracking';
import { useTracking } from 'utils/tracking/hooks';
import { tagComponent } from 'utils/tracking/tracking';

import styles from 'modules/favourites/components/FavouritesFlyoutEmpty/FavouritesFlyoutEmpty.scss';

const messageKeys = [
  LOCALIZATION.favouritesFlyoutEmptyPageDescription,
  LOCALIZATION.favouritesFlyoutEmptyPageContentTitle,
  LOCALIZATION.favouritesFlyoutEmptyPageContentText,
  LOCALIZATION.favouritesFlyoutEmptyPageLogInText,
  LOCALIZATION.favouritesDiscoveryButtonText,
];

const FavouritesFlyoutEmpty = (props: any) => {
  const tracking = useTracking(props, 'FavouritesFlyoutEmpty');
  const { isAnonymous } = useAuth();
  const isAccountEnabled = useFeatureFlagsEnabled('poseidon.account.enabled');
  const {
    category,
    customerAuthentication: { signIn },
  } = useRoutes();

  const [pageDescription, pageContentTitle, pageContentText, loginButtonText, buttonContent] =
    useMessage(messageKeys);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      tracking(event);
      event.currentTarget.blur();
    },
    [tracking],
  );

  return (
    <div className={styles.wrapper} data-purpose="favourites.empty">
      <Content tag="p" className={styles.paragraph} content={pageDescription} />
      <Link
        as={<Button className={styles.button} layout="block" variant="primary" />}
        data-purpose="favourites.discovery.button"
        onClick={handleClick}
        href={`/${category.name}/root`}
      >
        {buttonContent}
      </Link>

      {isAccountEnabled && (
        <FeedbackCard variant="normal" layout="inline" title={pageContentTitle}>
          <Content content={pageContentText} size="sm" tag="p" />
          {isAnonymous && (
            <Link
              as={<HydraLink layout="block" typography="small" theme="dark" underline />}
              data-purpose="favourites.login.button"
              onClick={handleClick}
              href={`/${signIn.name}`}
            >
              {loginButtonText}
            </Link>
          )}
        </FeedbackCard>
      )}
    </div>
  );
};

export default track(tagComponent('FavouritesFlyoutEmpty'))(FavouritesFlyoutEmpty);
