import React from 'react';
import { FeedbackCard } from '@xxxlgroup/hydra-ui-components';
import Message from 'components/Message';
import type { GraphQLErrorWithExtensions } from 'types/graphql';

/**
 * Render Feedback Card for Favourites errors
 */
export function renderError(errors: readonly GraphQLErrorWithExtensions[]) {
  return errors.map((error) => (
    <Message code={error.extensions?.errors[0]?.messageKey} key={error?.name}>
      {(message) => <FeedbackCard variant="error">{message}</FeedbackCard>}
    </Message>
  ));
}
