import { useCallback, useState, useEffect } from 'react';
import { local } from '@xxxlgroup/hydra-utils/storage';
import { useMutation } from '@apollo/client';
import { removeProductFromLocal } from 'utils/cart';
import { DELETE_CART_ENTRY_MUTATION } from 'pages/Cart/Cart.mutation';
import updateReservationCartStore from 'components/Entries/utils/actions/updateReservationCartStore';
import type { OrderEntry } from 'graphql-types/generated/types-generated';
import type { DeleteCartItem } from 'components/Entries/utils/actions/useCartRemove';
import { GraphQLErrors } from '@apollo/client/errors';

/**
 * A hook, which performs an entry removal from the reservation cart
 * @param {string} cartCode the unique id of the cart
 * @param {array} entries an array with the cart entries
 */

const useReservationsRemove = (cartCode: string, entries: OrderEntry[] = []) => {
  const [errors, setErrors] = useState<GraphQLErrors>([]);

  const [deleteCartEntryMutation] = useMutation(DELETE_CART_ENTRY_MUTATION, {
    update: (store, { data: { deleteCartEntry } }) => {
      updateReservationCartStore(store, deleteCartEntry);
    },
    onError: ({ graphQLErrors }) => {
      if (graphQLErrors?.length) {
        setErrors(graphQLErrors);
      }
    },
  });

  const removeEntry = useCallback(
    async (entryId: DeleteCartItem['entryNumber']) => {
      await deleteCartEntryMutation({
        variables: { cartId: cartCode, entryId },
      });

      removeProductFromLocal(cartCode, entryId);
    },
    [cartCode, deleteCartEntryMutation],
  );

  const remove = useCallback(
    (entryId: DeleteCartItem['entryNumber'], duplicates?: DeleteCartItem[]) => {
      if (duplicates) {
        duplicates.forEach((item) => {
          removeEntry(item.entryNumber);
        });
      } else {
        removeEntry(entryId);
      }
    },
    [removeEntry],
  );

  useEffect(() => {
    const cartProducts: DeleteCartItem[] = local.getItem('cart') || [];
    cartProducts.forEach((product) => {
      if (cartCode === product.cartId) {
        const productToRemove = entries.find((entry) => entry.entryNumber === product.entryNumber);
        entries.forEach((entry) => {
          if (entry && productToRemove?.product?.code === entry.product?.code) {
            remove(entry.entryNumber as DeleteCartItem['entryNumber']);
          }
        });
      }
    });
    // Needs to be executed only on load, to call remove() if anything is in local storage.
    // If we add suggested dependencies, remove() can be triggered on the actual delete action,
    // causing an error and an additional not needed call to remove().
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    remove,
    errors,
  };
};

export default useReservationsRemove;
