import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@xxxlgroup/hydra-ui-components';
import ScrollBox from 'components/ScrollBox';
import Link from 'components/WebshopLink';
import useMessage from 'components/Message/useMessage';
import PriceSummary from 'components/Entries/EntryLists/ListingMini/components/PriceSummary';
import styles from 'components/Entries/EntryLists/ListingMini/ListingMini.scss';

const ListingMini = ({ children, linkToPage, priceSummary, toPageMessageCode, trackingData }) => {
  const toPage = useMessage(toPageMessageCode);

  const handleClick = (event) => {
    event.currentTarget.blur();
  };

  return (
    <>
      <ScrollBox dataPurpose="miniCart.wrapper.productList">{children}</ScrollBox>
      {priceSummary && <PriceSummary price={priceSummary} />}
      <Link
        as={
          <Button
            className={styles.button}
            data-purpose="productList.miniCart"
            variant="primary"
            layout="block"
          />
        }
        onClick={handleClick}
        href={linkToPage}
        trackingData={trackingData}
      >
        {toPage}
      </Link>
    </>
  );
};

ListingMini.propTypes = {
  /** list of entries used in ScrollBox */
  children: PropTypes.node.isRequired,
  /** name of the linked page */
  linkToPage: PropTypes.string,
  /** price summary value */
  priceSummary: PropTypes.number,
  /** message code for the button text */
  toPageMessageCode: PropTypes.string.isRequired,
  /** additional tracking data for the redirect button */
  trackingData: PropTypes.shape({
    purpose: PropTypes.string,
  }),
};

ListingMini.defaultProps = {
  linkToPage: null,
  priceSummary: null,
};

export default ListingMini;
