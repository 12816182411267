import { useState, useCallback, useEffect } from 'react';
import { debouncing } from 'utils/function';
import { addProductToLocal, removeProductFromLocal } from 'utils/cart';
import { TIMEOUT_REMOVE_ENTRY } from 'constantsDefinitions';
import { local } from '@xxxlgroup/hydra-utils/storage';
import type { Favourite, OrderEntry } from 'graphql-types/generated/types-generated';
import type { GraphQLErrors } from '@apollo/client/errors';

interface UseOverlayDeletedTimer {
  /** the cart code */
  cartCode: string;
  /** the entry data for an order entry */
  entryData?: OrderEntry;
  /** entry data for a favourite entry */
  entryDataFavourite?: Favourite;
  /** a callback executed when deleting timeout is over */
  onRemove: (entryNumber?: string | number | null, duplicates?: OrderEntry[]) => void;
  /** a product could be reserved in many subsidiaries.
   * In order to remove the product correctly we need an array of the duplicated products
   */
  duplicates?: OrderEntry[];
  /** errors on failed product deletion */
  errorsOnRemove?: GraphQLErrors;
}

/**
 * This hook is used in combination with the OverlayDeleted component. It returns functions for controlling the entry removing process
 */
const useOverlayDeletedTimer = ({
  cartCode,
  entryData,
  entryDataFavourite,
  onRemove,
  duplicates,
  errorsOnRemove = [],
}: UseOverlayDeletedTimer) => {
  const [isRemoving, setIsRemoving] = useState(false);
  const { entryNumber, product } = entryData ?? {};
  const { id, item } = entryDataFavourite ?? {};
  const { code } = product ?? {};
  const entryNumberFavourite = item?.entryNumber || id;
  const number = entryData ? entryNumber : entryNumberFavourite;

  useEffect(() => {
    if (errorsOnRemove?.length) {
      setIsRemoving(false);
    }
  }, [errorsOnRemove]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedRemove = useCallback(
    debouncing(() => {
      if (code) {
        local.removeItem(`services-${code}`);
      }

      onRemove(number, duplicates);
    }, TIMEOUT_REMOVE_ENTRY),
    [duplicates, number],
  );

  const initRemoving = () => {
    setIsRemoving(true);
    addProductToLocal(cartCode, number);
    delayedRemove();
  };

  const revertRemoving = () => {
    delayedRemove.cancel();
    removeProductFromLocal(cartCode, number);
    setIsRemoving(false);
  };

  const cancelRemoving = () => {
    delayedRemove.cancel();
  };

  return {
    initRemoving,
    revertRemoving,
    isRemoving,
    cancelRemoving,
  };
};

export default useOverlayDeletedTimer;
