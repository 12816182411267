import React from 'react';
import PropTypes from 'prop-types';
import { PriceCurrent } from '@xxxlgroup/hydra-ui-components';
import useMessage from 'components/Message/useMessage';
import useLanguage from 'hooks/useLanguage';
import styles from 'components/Entries/EntryLists/ListingMini/components/PriceSummary/PriceSummary.scss';

const PriceSummary = ({ price }) => {
  const { currencyFormat } = useLanguage();
  const [subTotalMessage, subTotalWithoutDeliveryCostMessage] = useMessage([
    'wxs.priceSummary.subTotal',
    'wxs.priceSummary.subTotal.without.deliveryCost',
  ]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{subTotalMessage}</div>
      <PriceCurrent
        className={styles.price}
        currencyFormat={currencyFormat}
        value={price}
        size="small"
      />
      <span className={styles.subText}>{subTotalWithoutDeliveryCostMessage}</span>
    </div>
  );
};

PriceSummary.propTypes = {
  /** summary price amount */
  price: PropTypes.number.isRequired,
};

export default PriceSummary;
