import React from 'react';
import classnames from 'classnames';
import { trashCan } from '@xxxlgroup/hydra-icons';
import { IconButton } from '@xxxlgroup/hydra-ui-components';
import useMessage from 'components/Message/useMessage';
import styles from 'components/Entries/EntryCompound/components/DeleteButton/DeleteButton.scss';

interface ContactCenterLinkProps {
  /** Sets a class to give customized styles to the div element. */
  className?: string | null;
  /** handles click */
  onClick: (event: React.MouseEvent<Element, MouseEvent>) => void;
}

const DeleteButton = (props: ContactCenterLinkProps) => {
  const { className = null, onClick } = props;
  const title = useMessage('wxs.productList.item.remove');

  return (
    <IconButton
      className={classnames(styles.button, className)}
      data-purpose="entryItem.remove"
      glyph={trashCan}
      onClick={onClick}
      title={title}
      type="button"
    />
  );
};

export default DeleteButton;
