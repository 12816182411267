import { useCallback, useState, useEffect } from 'react';
import { local } from '@xxxlgroup/hydra-utils/storage';
import { useMutation } from '@apollo/client';
import { GraphQLErrors } from '@apollo/client/errors';
import { removeProductFromLocal, updateWithCurrentCartResponse } from 'utils/cart';
import { DELETE_CART_ENTRY_MUTATION } from 'pages/Cart/Cart.mutation';
import type { MutationDeleteCartEntryArgs } from 'graphql-types/generated/types-generated';
import { Cart } from 'types/types';

export type DeleteCartItem = Omit<MutationDeleteCartEntryArgs, 'entryId'> & {
  entryNumber: MutationDeleteCartEntryArgs['entryId'];
};

/**
 * A hook, which performs an entry removal from the cart
 * @param {object} cart the user's cart
 */

const useCartRemove = (cart: Cart | null) => {
  const { code: cartCode } = cart || { code: '' };
  const [errors, setErrors] = useState<GraphQLErrors>([]);
  const [deleteCartEntryMutation, { error: mutationError }] = useMutation(
    DELETE_CART_ENTRY_MUTATION,
    {
      update: (store, { data: { deleteCartEntry } }) => {
        updateWithCurrentCartResponse(store, deleteCartEntry);
      },
    },
  );

  const remove = useCallback(
    async (entryId: DeleteCartItem['entryNumber']) => {
      try {
        await deleteCartEntryMutation({
          variables: { cartId: cartCode, entryId },
        });
      } catch {
        if (mutationError?.graphQLErrors?.length) {
          setErrors(mutationError.graphQLErrors);
          return;
        }
      }

      removeProductFromLocal(cartCode, entryId);
    },
    [cartCode, deleteCartEntryMutation, mutationError],
  );

  useEffect(() => {
    const cartProducts: DeleteCartItem[] = local.getItem('cart') || [];
    cartProducts.forEach((product) => {
      if (cart?.code === product.cartId) {
        remove(product.entryNumber);
      }
    });
    // Needs to be executed only on load, to call remove() if anything is in local storage.
    // If we add suggested dependencies, remove() can be triggered on the actual delete action,
    // causing an error and an additional not needed call to remove().
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    remove,
    errors,
  };
};

export default useCartRemove;
