import React from 'react';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import EntryCompound from 'components/Entries/EntryCompound';
import Attributes from 'components/Entries/EntryCompound/components/Attributes';
import EnergyLabel from 'components/EnergyLabel';
import DeleteButton from 'components/Entries/EntryCompound/components/DeleteButton';
import Image from 'components/Entries/EntryCompound/components/Image';
import Name from 'components/Entries/EntryCompound/components/Name';
import OverlayDeleted from 'components/Entries/EntryCompound/components/OverlayDeleted';
import Price from 'components/Entries/EntryCompound/components/Price';
import EcoTax from 'components/Entries/EntryCompound/components/EcoTax';
import useOverlayDeletedTimer from 'components/Entries/EntryCompound/components/OverlayDeleted/useOverlayDeletedTimer';
import type { Favourite } from 'graphql-types/generated/types-generated';

import styles from 'modules/favourites/components/FavouritesMini/FavouritesMini.scss';

interface FavouritesMiniProps {
  /** The entry data */
  entryData: Favourite;
  /** A callback for removing the entry */
  onRemove: (entryId?: string | number | null) => void;
}

const FavouritesMini = (props: FavouritesMiniProps) => {
  const { entryData, onRemove } = props;
  const tracking = useTracking(props, 'FavouritesMini');
  const { item } = entryData;
  const { product } = item ?? {};
  const { additionalProductExpenses, energyEfficiencyData, mediaData, name } = product ?? {};
  const { initRemoving, revertRemoving, isRemoving, cancelRemoving } = useOverlayDeletedTimer({
    cartCode: 'favourites',
    entryDataFavourite: entryData,
    onRemove,
  });

  const handleRevert = (event: React.MouseEvent<Element, MouseEvent>) => {
    tracking(event);
    revertRemoving();
  };

  const handleRemove = (event: React.MouseEvent<Element, MouseEvent>) => {
    tracking(event, {
      data: {
        entryData,
      },
    });
    initRemoving();
  };

  const onMouseLeave = () => {
    initRemoving();
  };

  const onMouseEnter = () => {
    cancelRemoving();
  };

  if (isRemoving && name) {
    return (
      <div className={styles.overlayWrapper}>
        <OverlayDeleted
          onMouseLeave={onMouseLeave}
          onMouseEnter={onMouseEnter}
          name={name}
          onRevert={handleRevert}
          revertMessageCode="wxs.productList.item.revertSavedForLater"
        />
      </div>
    );
  }

  if (!product) {
    return null;
  }

  return (
    <EntryCompound className={styles.wrapper} product={product}>
      <Name headingLevel={4} className={styles.name} />
      <Image className={styles.image} />
      <Attributes withDisabledKeys className={styles.attributes} />
      {energyEfficiencyData && (
        <EnergyLabel
          className={styles.energyLabel}
          classNameLink={styles.energyLink}
          energyEfficiencyData={energyEfficiencyData}
          mediaData={mediaData}
          withDataSheet={false}
        />
      )}
      <DeleteButton onClick={handleRemove} className={styles.button} />
      <Price alignment="right" className={styles.price} />
      <EcoTax additionalProductExpenses={additionalProductExpenses} />
    </EntryCompound>
  );
};

export default track(tagComponent('FavouritesMini'))(FavouritesMini);
