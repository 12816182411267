import useFeatureFlagsEnabled from 'hooks/useFeatureFlagsEnabled';
import { useAuth } from 'modules/customer-authentication/AuthContext';

/**
 * Returns a boolean whether to render the requested HeaderActionIcon.
 * @param {boolean} loginActionIcon If true, the boolean for Login HeaderActionIcon is returned, otherwise the boolean for Logout HeaderActionsIcon is returned.
 * @returns True if the requested HeaderActionIcon should be rendered, false otherwise.
 */
const useRenderHeaderActionIcons = (loginActionIcon) => {
  const { isAnonymous, isPoseidonLoginEnabled } = useAuth();
  const isAccountEnabled = useFeatureFlagsEnabled('poseidon.account.enabled');

  const show = isPoseidonLoginEnabled && isAccountEnabled;
  const result = loginActionIcon ? isAnonymous : !isAnonymous;

  return show ? result : false;
};

export default useRenderHeaderActionIcons;
