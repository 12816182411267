import React, { FC, MouseEvent } from 'react';
import classnames from 'classnames';
import { FeedbackCard, Link as HydraLink } from '@xxxlgroup/hydra-ui-components';
import useMessage from 'components/Message/useMessage';
import { noop } from '@xxxlgroup/hydra-utils/common';
import styles from 'components/Entries/EntryCompound/components/OverlayDeleted/OverlayDeleted.scss';

export interface OverlayDeletedProps {
  /** Message code for the action text */
  actionMessageCode?: string;
  /** Message code for the aria label of the revert button */
  ariaLabelMessageCode?: string;
  /** Data purpose */
  dataPurpose?: string;
  /** Should the items be adaptive for the different breakpoints */
  isAdaptive?: boolean;
  /** Name of the product */
  name: string;
  /** Callback, when entering overlay */
  onMouseEnter?: () => void;
  /** Callback, when leaving overlay */
  onMouseLeave?: () => void;
  /** Callback for reverting the deleted entry back to cart */
  onRevert: (event: MouseEvent) => void;
  /** Message code for the reverting text */
  revertMessageCode?: string;
}

const OverlayDeleted: FC<OverlayDeletedProps> = ({
  isAdaptive = false,
  name,
  onRevert,
  ariaLabelMessageCode = 'wxs.productList.item.revert',
  revertMessageCode = 'wxs.productList.item.revert',
  actionMessageCode = 'wxs.productList.item.removed',
  dataPurpose = 'entry.remove.revert',
  onMouseEnter = noop,
  onMouseLeave = noop,
}) => {
  const ariaLabel = useMessage(ariaLabelMessageCode, null, true);
  const [messageRemoved, messageRevert] = useMessage([actionMessageCode, revertMessageCode]);

  return (
    <FeedbackCard onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} variant="success">
      <span className={classnames({ [styles.adaptive]: isAdaptive })}>
        <span className={styles.wrapperName}>
          <span className={styles.name}>{`${name} `}</span>
          {messageRemoved}
        </span>
        <HydraLink
          aria-label={ariaLabel}
          data-purpose={dataPurpose}
          onClick={onRevert}
          theme="coal"
          typography="small"
          underline
        >
          {messageRevert}
        </HydraLink>
      </span>
    </FeedbackCard>
  );
};

export default OverlayDeleted;
