import React, { type FC, type MouseEvent } from 'react';
import classNames from 'classnames';
import Link from 'components/WebshopLink';
import { Icon } from '@xxxlgroup/hydra-ui-components';
import { Glyph } from '@xxxlgroup/hydra-ui-components/dist/common/types/typeDefinitions';
import { chevronRight } from '@xxxlgroup/hydra-icons';

import styles from 'modules/customer-account/components/AccountNavigationItem/AccountNavigationItem.scss';

interface AccountNavigationItemProps {
  /** icon shown next to the title */
  glyph?: Glyph;
  /** title of the item */
  title: string;
  /** link to another page */
  link?: string;
  /** Whether the item should be rendered in its active state. */
  isActive?: boolean;
  /** Indicates whether the navigation item is direction from the account page.  */
  isComingFromAccount?: boolean;
  /*  name of the item */
  navigationItem?: string;
}

const AccountNavigationItem: FC<AccountNavigationItemProps> = ({
  glyph,
  title,
  link,
  isActive,
  isComingFromAccount,
  navigationItem,
}) => (
  <Link
    className={classNames(styles.link, { [styles.active]: isActive })}
    href={link}
    state={isComingFromAccount ? { fromAccount: true } : null}
    data-purpose={`account.navigation.item.link.${navigationItem}`}
    onMouseLeave={(event: MouseEvent<HTMLAnchorElement>) => {
      event.currentTarget.blur();
    }}
  >
    {glyph && <Icon glyph={glyph} className={styles.icon} aria-hidden="true" />}
    <span className={styles.title}>{title}</span>
    <Icon glyph={chevronRight} className={styles.chevron} />
  </Link>
);

export default AccountNavigationItem;
