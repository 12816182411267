import { generatePath, matchPath } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import {
  cart,
  cogs,
  clock,
  heart,
  check,
  circleQuestionmark,
  location,
  percentBadge,
  user,
} from '@xxxlgroup/hydra-icons';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useMessage from 'components/Message/useMessage';
import useRoutes from 'hooks/useRoutes';

export const navigationItems = [
  'purchases',
  'favourites',
  'customerCard',
  'reservations',
  'manageAccount',
  'addressBook',
  'newsletter',
  'paymentMethods',
  'helpAndContact',
];

const glyphMap = {
  purchases: cart,
  reservations: clock,
  customerCard: percentBadge,
  addressBook: location,
  manageAccount: user,
  newsletter: check,
  helpAndContact: circleQuestionmark,
  favourites: heart,
  paymentMethods: cogs,
};

const featureFlagPrefix = 'poseidon.account.page.';
const msgPrefix = 'account.navigation.';

const useAccountNavigationItems = (items) => {
  const match = useRouteMatch();
  const routes = useRoutes();
  const helpAndContactUrl = useMessage('wxs.contactbox.twovariant.help.url');

  const routePathMap = {
    purchases: routes.customerAccount.purchases.root,
    reservations: `/${routes.customerAccount.name}`,
    customerCard: routes.customerAccount.customerCard.root,
    addressBook: routes.customerAccount.addressBook.root,
    manageAccount: routes.customerAccount.userData.root,
    newsletter: `/${routes.customerAccount.name}`,
    helpAndContact: helpAndContactUrl,
    favourites: routes.favouritesPage.root,
    paymentMethods: `/${routes.customerAccount.name}`,
  };

  const finalItems = items === undefined ? navigationItems : items;
  const itemsArr = Array.isArray(finalItems) ? finalItems : [finalItems];

  const itemsFeatureFlags = useFeatureFlags(
    navigationItems.map((navigationItem) => `${featureFlagPrefix}${navigationItem}`),
  );
  const itemsTranslations = useMessage(
    navigationItems.map((navigationItem) => `${msgPrefix}${navigationItem}`),
  );

  return itemsArr
    .map((navigationItem) => {
      const index = navigationItems.indexOf(navigationItem);
      if (index > -1) {
        const routePathEntry = routePathMap[navigationItem];
        return {
          link: generatePath(routePathEntry),
          active: !!matchPath(match.url, {
            path: routePathEntry,
            exact: true,
          }),
          glyph: glyphMap[navigationItem],
          enabled: itemsFeatureFlags[index],
          translation: itemsTranslations[index],
          navigationItem,
        };
      }
      return null;
    })
    .filter((item) => item !== null);
};

export default useAccountNavigationItems;
