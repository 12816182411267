import React from 'react';
import PropTypes from 'prop-types';
import useAccountNavigationItems, {
  navigationItems,
} from 'modules/customer-account/hooks/useAccountNavigationItems';
import AccountNavigationItem from 'modules/customer-account/components/AccountNavigationItem';
import classNames from 'classnames';
import styles from 'modules/customer-account/components/AccountNavigation/AccountNavigation.scss';

const mainNavItemNames = ['purchases', 'favourites', 'customerCard'];
const secondaryNavItemNames = navigationItems.filter((item) => !mainNavItemNames.includes(item));

const AccountNavigation = ({ className }) => {
  const mainNavItems = useAccountNavigationItems(mainNavItemNames);
  const secondaryNavItems = useAccountNavigationItems(secondaryNavItemNames);

  const renderNavItem = (navItem, lastItem = false) => {
    if (!navItem.enabled) {
      return null;
    }
    return (
      <li className={lastItem ? styles.lastMainNavItem : null} key={navItem.translation}>
        <AccountNavigationItem
          isActive={navItem.active}
          title={navItem.translation}
          glyph={navItem.glyph}
          link={navItem.link}
          navigationItem={navItem.navigationItem}
          isComingFromAccount
        />
      </li>
    );
  };

  return (
    <nav aria-labelledby="account-heading">
      <ul className={classNames(styles.wrapper, className)}>
        {mainNavItems.map((navItem, index) =>
          renderNavItem(navItem, index === mainNavItems.length - 1),
        )}
        {secondaryNavItems.map((navItem) => renderNavItem(navItem))}
      </ul>
    </nav>
  );
};

AccountNavigation.propTypes = {
  /** @ignore */
  className: PropTypes.node,
};

AccountNavigation.defaultProps = {
  className: null,
};

export default AccountNavigation;
