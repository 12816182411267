import { ApolloCache } from '@apollo/client';
import { Cart } from 'graphql-types/generated/types-generated';
import { GET_CARTS_QUERY } from 'pages/Reservation/Reservation.query';
import { updateCurrentCart } from 'utils/cart';

/**
 * Updates the reservation entries data all over the shop after a mutation
 * @param {object} store the gql store
 * @param {function} updateCartEntry the user cart
 */

const updateReservationCartStore = (store: ApolloCache<any>, updateCartEntry: Cart) => {
  const query = store.readQuery({ query: GET_CARTS_QUERY }) || {};
  const data = updateCurrentCart(query, updateCartEntry);
  store.writeQuery({ data, query: GET_CARTS_QUERY });
};

export default updateReservationCartStore;
