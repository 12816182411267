import { gql } from '@apollo/client';
import { cart } from 'graphql-fragments';

export const GET_CARTS_QUERY = gql`
  query getCarts($type: String = "RESERVATION") {
    getCarts(type: $type) {
      carts {
        ${cart}
      }
    }
  }
`;
