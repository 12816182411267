import ErrorHandler from 'components/ErrorHandler';
import React from 'react';

// Prevent error handling when 401 Unauthorized, display empty cart instead
export const handleNotUnauthorized = (error) => {
  const { graphQLErrors = [] } = error || {};
  const [{ extensions: { statusCode } = {} } = {}] = graphQLErrors;

  return error && statusCode !== 401 ? (
    <ErrorHandler statusCode={error.statusCode} uid={error.uid} />
  ) : null;
};
