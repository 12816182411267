import React, { FC } from 'react';
import { Heading, Loading } from '@xxxlgroup/hydra-ui-components';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';
import { renderError } from 'modules/favourites/utils/errors';
import { handleNotUnauthorized } from 'utils/customHandlers';
import ErrorBoundary from 'components/ErrorBoundary';
import useRoutes from 'hooks/useRoutes';
import useMessage from 'components/Message/useMessage';
import useCartRemove from 'components/Entries/utils/actions/useCartRemove';
import { getTotalProductsAmount } from 'utils/cart/misc';
import Entry from 'components/Entries/EntryItems/CartMini';
import ListingMini from 'components/Entries/EntryLists/ListingMini';
import CartMiniEmpty from 'components/Entries/EntryLists/CartMiniEmpty';
import { CartPageContextData, useCartContext } from 'pages/Cart/contexts/CartContext';
import { Entry as EntryType } from 'types/types';

import styles from 'components/Entries/EntryLists/CartMini/CartMini.scss';
import type { GraphQLErrorWithExtensions } from 'types/graphql';

interface CartMiniProps {
  orderCarts: {
    error?: GraphQLErrorWithExtensions[];
    loading?: boolean;
  };
}

const CartMini: FC<CartMiniProps> = ({ orderCarts }) => {
  const context = useCartContext() as CartPageContextData;

  const { error, loading } = orderCarts || {};
  const errorHandler = handleNotUnauthorized(error);
  const { cart: cartPage } = useRoutes();
  const { remove, errors: errorsOnRemove } = useCartRemove(context?.cart);
  const showEntries = !isArrayEmpty(context?.cart?.entries ?? []);
  const showErrorsOnRemove = !errorsOnRemove.length;
  const numberOfEntries = getTotalProductsAmount(context?.cart?.entries ?? []);

  const dataPurpose = !showEntries ? 'cart.emptyCart' : null;
  const [heading, entriesCount] = useMessage(
    ['wxs.checkout.cart.title', 'wxs.reservation.number.articles'],
    {
      entries: numberOfEntries,
    },
  );

  if (!context?.cart) {
    return null;
  }

  if (loading) {
    return <Loading />;
  }

  if (errorHandler) {
    return errorHandler;
  }

  return (
    <ErrorBoundary withStatusCode>
      <div className={styles.wrapper} data-testid="miniCart.wrapper" data-purpose={dataPurpose}>
        <Heading
          className={styles.title}
          content={heading}
          data-purpose="mini.cart.heading"
          level={4}
          SEO="h3"
        />
        {showEntries && <div className={styles.count}>{entriesCount}</div>}
        {showErrorsOnRemove && renderError(errorsOnRemove as any)}{' '}
        {/* is needed because of type interferences */}
        {showEntries ? (
          <ListingMini
            linkToPage={cartPage}
            priceSummary={context.cart.subTotal?.value}
            toPageMessageCode="wxs.checkout.to.cart"
            trackingData={{ purpose: 'cart.mini.to.cart.button' }}
          >
            {context.cart.entries.map((entry: EntryType) => (
              <Entry
                cartCode={context.cart?.code ?? ''}
                entryData={entry}
                key={entry.entryNumber}
                onRemove={remove}
              />
            ))}
          </ListingMini>
        ) : (
          <CartMiniEmpty />
        )}
      </div>
    </ErrorBoundary>
  );
};

export default CartMini;
