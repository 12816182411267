import React from 'react';
import classNames from 'classnames';
import { Heading } from '@xxxlgroup/hydra-ui-components';
import { SEOLevel } from '@xxxlgroup/hydra-ui-components/dist/common/types/typeDefinitions';

import Message from 'components/Message';
import Greeting from 'modules/customer-account/components/Greeting';
import AccountGreetingWithInput from 'modules/customer-account/components/AccountGreeting/components/AccountGreetingWithInput';

import styles from 'modules/customer-account/components/AccountGreeting/AccountGreeting.scss';

interface AccountGreetingProps {
  /** @ignore */
  className?: string;
  /** classname of the heading */
  headingClassName?: string;
  /** if greeting with the input field */
  isEditable?: boolean;
  /** SEOLevel applied to the heading */
  SEOLevel?: boolean | SEOLevel;
}

const AccountGreeting = ({
  className,
  headingClassName,
  isEditable,
  SEOLevel,
}: AccountGreetingProps) => (
  <Greeting skeletonClassName={styles.loading} className={className}>
    {({ greeting, profileName }) => {
      if (isEditable && !profileName) {
        return (
          <AccountGreetingWithInput
            className={classNames(styles.heading, headingClassName)}
            SEOLevel={SEOLevel}
          />
        );
      }
      return (
        <Message code="account.greeting.title" values={{ name: greeting }}>
          {(translation) => (
            <Heading
              className={classNames(styles.heading, headingClassName)}
              content={translation}
              level={2}
              noMargin
              SEO={SEOLevel}
            />
          )}
        </Message>
      );
    }}
  </Greeting>
);

export default AccountGreeting;
