import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link as HydraLink } from '@xxxlgroup/hydra-ui-components';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';
import * as icons from '@xxxlgroup/hydra-icons';
import type { MetaNavigationNode } from 'graphql-types/generated/types-generated';
import Link from 'components/WebshopLink';
import styles from 'components/MetaNavigation/components/LinkCarousel/LinkCarousel.scss';

export const SLIDE_INTERVAL_MS = 3500;

interface LinkCarouselProps {
  promotionData: MetaNavigationNode[];
}

const LinkCarousel = ({ promotionData }: LinkCarouselProps) => {
  const [index, setIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    let intervalInnerHandler: number;

    if (!isArrayEmpty(promotionData)) {
      intervalInnerHandler = setInterval(() => {
        if (isHovered) {
          return;
        }

        setIndex((index) => (index + 1) % promotionData.length);
      }, SLIDE_INTERVAL_MS);
    }

    return () => {
      intervalInnerHandler && clearInterval(intervalInnerHandler);
    };
  }, [isHovered, promotionData]);

  const renderLink = useCallback(
    ({ iconName, link }: MetaNavigationNode) => (
      <Link
        key={link!.url}
        as={
          <HydraLink
            glyphAfter={iconName ? icons[iconName] : null}
            glyphBefore={iconName ? icons[iconName] : null}
            theme="ash"
            className={styles.link}
          />
        }
        data-purpose="metaNavigation.promotion.link"
        href={link!.url}
        target={link!.target ?? '_self'}
      >
        {link!.linkName}
      </Link>
    ),
    [],
  );

  const links = useMemo(
    () => promotionData.filter(({ link }) => !!link).map(renderLink),
    [promotionData, renderLink],
  );

  return (
    <div
      className={styles.carouselWrapper}
      onMouseEnter={() => setIsHovered(true)}
      onFocus={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onBlur={() => setIsHovered(false)}
    >
      <div
        className={styles.carouselContent}
        data-purpose="metanav.linkCarousel.content"
        style={{ transform: `translateY(-${index * 100}%)` }}
      >
        {links}
      </div>
    </div>
  );
};

export default LinkCarousel;
