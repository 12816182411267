import React from 'react';
import { Link as HydraLink } from '@xxxlgroup/hydra-ui-components';
import useMessage from 'components/Message/useMessage';
import AccountNavigation from 'modules/customer-account/components/AccountNavigation';
import AccountGreeting from 'modules/customer-account/components/AccountGreeting';
import { useAuth } from 'modules/customer-authentication/AuthContext';

import styles from 'modules/customer-authentication/components/LogoutFlyout/LogoutFlyout.scss';

const LogoutFlyout = () => {
  const { logout } = useAuth();
  const logoutButtonText = useMessage('user.logout.button');

  return (
    <div className={styles.wrapper}>
      <div className={styles.greeting}>
        <AccountGreeting headingClassName={styles.heading} SEOLevel="h4" />
      </div>
      <div className={styles.menu}>
        <AccountNavigation className={styles.accountNavWrapper} />
        <HydraLink onClick={logout} theme="coal" data-testid="account.navigation.logout.button">
          {logoutButtonText}
        </HydraLink>
      </div>
    </div>
  );
};

export default LogoutFlyout;
