import React from 'react';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';
import LoginForm from 'modules/customer-authentication/components/LoginForm';
import styles from 'modules/customer-authentication/components/LoginFlyout/LoginFlyout.scss';

const LoginFlyout = () => {
  const handleClick = (event) => {
    event.currentTarget.blur();
  };

  return (
    <div className={styles.wrapper}>
      <LoginForm heading="small" onClickSignUp={handleClick} />
    </div>
  );
};

export default track(tagComponent('LoginFlyout'))(LoginFlyout);
