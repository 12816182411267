import React, { FC, memo } from 'react';
import classNames from 'classnames';
import track from 'react-tracking';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';
import { Link as HydraLink } from '@xxxlgroup/hydra-ui-components';
import {
  MetaNavigationNode,
  useGetMetaNavigationQuery,
} from 'graphql-types/generated/types-generated';
import { useExperiment } from '@xxxlgroup/xxxl-a-b-testing';
import Link from 'components/WebshopLink';
import ContrastToggle from 'components/ContrastToggle';
import LinkCarousel from 'components/MetaNavigation/components/LinkCarousel';
import LanguageSelector from 'components/MetaNavigation/components/LanguageSelector';
import useFeatureFlagsEnabled from 'hooks/useFeatureFlagsEnabled';
import { tagComponent } from 'utils/tracking/tracking';
import { ColorScheme } from 'components/ContrastToggle/ContrastToggle';

import styles from 'components/MetaNavigation//MetaNavigation.scss';

const MetaNavigation: FC = () => {
  const { data, loading } = useGetMetaNavigationQuery();
  const isLanguageSelectorEnabled = useFeatureFlagsEnabled(
    'poseidon.multi.language.support.enabled',
  );

  const { variant, variants } = useExperiment('languageSelectorTest');
  const isLanguageSelectorTestEnabled = variant === variants.default;

  const shouldLanguageSelectorRender = isLanguageSelectorEnabled && isLanguageSelectorTestEnabled;

  const promotionData = data?.getMetaNavigation?.promotionNodes;
  const serviceNodes = data?.getMetaNavigation?.serviceNodes;

  return (
    <div
      className={classNames(styles.metaNavigation, {
        [styles.serviceOnlyWrapper]: !promotionData || isArrayEmpty(promotionData),
      })}
      data-purpose="meta.navigation"
    >
      <div
        className={classNames(styles.wrapper, {
          [styles.serviceOnly]: !promotionData || isArrayEmpty(promotionData),
        })}
      >
        {!loading && (
          <>
            {promotionData && !isArrayEmpty(promotionData) && (
              <LinkCarousel promotionData={promotionData as MetaNavigationNode[]} />
            )}
            <div data-testid="metaNav.service.container" className={styles.container}>
              <div className={styles.linkGroup} data-purpose="metaNav.service.nodes">
                {serviceNodes?.map((node) => (
                  <Link
                    as={<HydraLink className={styles.link} theme="ash" />}
                    data-purpose="metaNavigation.service.link"
                    href={node?.link?.url}
                    key={node?.link?.url}
                    target={node?.link?.target ?? '_self'}
                  >
                    {node?.link?.linkName}
                  </Link>
                ))}
                <ContrastToggle className={styles.contrastToggle} colorScheme={ColorScheme.light} />
              </div>
              {shouldLanguageSelectorRender && <LanguageSelector />}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default memo(track(tagComponent('MetaNavigation'))(MetaNavigation));
