import React, { FC } from 'react';
import classnames from 'classnames';
import { replaceDotForComma } from 'utils/common';
import useMessage from 'components/Message/useMessage';
import styles from 'components/Entries/EntryCompound/components/Quantity/Quantity.scss';
import { UnitBasedCalculation } from 'graphql-types/generated/types-generated';

interface QuantityProps {
  /** The amount of a no unit based product */
  amount?: number | null;
  /** @ignore */
  className?: string;
  /** SearchCollector checkout tracking attribute that identifies the component type */
  dataTrackId?: 'checkoutQuantity';
  /** The amount of a unit based product */
  unitBased?: {
    packageAmount: UnitBasedCalculation['packageAmount'];
    deliveryAmount: UnitBasedCalculation['deliveryAmount'];
  };
  isPlacedAlternative?: boolean;
}

const Quantity: FC<QuantityProps> = ({
  className,
  amount = 1,
  dataTrackId,
  unitBased,
  isPlacedAlternative,
}) => {
  const { packageAmount, deliveryAmount } = unitBased ?? {};
  const [packageAmountMessage, pricePerPieceMessage, unit] = useMessage(
    ['ppu.calculation.cart.packages', 'wxs.productList.textSummary', 'ppu.calculator.unit'],
    {
      packageAmount: packageAmount ?? '',
      amount: amount ?? '',
    },
  );

  const quantityMessage = unitBased ? packageAmountMessage : pricePerPieceMessage;
  const subText = unitBased ? `= ${replaceDotForComma(Number(deliveryAmount))} ${unit}` : '';

  if (!quantityMessage) {
    return null;
  }

  return (
    <div
      className={classnames(styles.wrapper, className, {
        [styles.placedAlternative]: isPlacedAlternative,
      })}
      data-purpose="productEntry.quantity"
    >
      <span className={styles.quantity} data-track-id={dataTrackId}>
        {quantityMessage}
      </span>
      {subText && <span className={styles.subText}>{subText}</span>}
    </div>
  );
};

export default Quantity;
